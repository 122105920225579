import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { LanguageCodeEnum, RegularCategoryType, useDescendantsOfRegularCategoryQuery } from 'generated/graphql';
import { Box, Typography } from '@mui/material';
import CategoriesRow from './CategoriesRow';
import { useCategoryTreeStore, useDocumentStore } from 'boards/DetailBoardWrite/stores';

export default () => {
  // 번역도구
  const { t } = useTranslation();
  // URL 파생 정보
  const { lang } = useParams<{ lang?: LanguageCodeEnum }>();
  const { webtoon, game, lecture } = useDocumentStore((state) => ({
    webtoon: state.webtoon,
    game: state.game,
    lecture: state.lecture,
  }));
  const { setCategoryTree } = useCategoryTreeStore((state) => ({ setCategoryTree: state.setCategoryTree }));

  const { data: genreData, loading: isGenreDataLoading } = useDescendantsOfRegularCategoryQuery({
    variables: {
      descendantsOfRegularCategoryInput: {
        languageCode: lang,
        regularCategoryType: RegularCategoryType.Genre,
      },
    },
  });
  const { data: themeData, loading: isThemeDataLoading } = useDescendantsOfRegularCategoryQuery({
    variables: {
      descendantsOfRegularCategoryInput: {
        languageCode: lang,
        regularCategoryType: RegularCategoryType.Theme,
      },
    },
  });
  const { data: gameData, loading: isGameDataLoading } = useDescendantsOfRegularCategoryQuery({
    variables: {
      descendantsOfRegularCategoryInput: {
        languageCode: lang,
        regularCategoryType: RegularCategoryType.Game,
      },
    },
  });
  const { data: lectureData, loading: isLectureDataLoading } = useDescendantsOfRegularCategoryQuery({
    variables: {
      descendantsOfRegularCategoryInput: {
        languageCode: lang,
        regularCategoryType: RegularCategoryType.Lecture,
      },
    },
  });

  // 로딩중 여부
  const isLoading = !(!isGenreDataLoading && !isThemeDataLoading && !isGameDataLoading && !isLectureDataLoading);

  useEffect(() => {
    if (!isLoading) {
      setCategoryTree({
        genre: genreData.descendantsOfRegularCategory,
        theme: themeData.descendantsOfRegularCategory,
        game: gameData.descendantsOfRegularCategory,
        lecture: lectureData.descendantsOfRegularCategory,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, genreData, themeData, gameData, lectureData]);

  if (!webtoon && !game && !lecture) return null;
  return (
    <Box mt={3}>
      {(webtoon || game) && (
        <Typography color="#000000DE" fontWeight="500" fontSize="14px" lineHeight="22px" letterSpacing="0.1px">
          {t('document.categories.title')}
        </Typography>
      )}
      {webtoon && (
        <>
          <Typography mt={1.5} color="#000000DE" fontWeight="700" fontSize="14px" lineHeight="22px" letterSpacing="0.1px">
            [{t('document.toon')}] {t('document.categories.title')}
          </Typography>
          <CategoriesRow type="genre" />
          <CategoriesRow type="theme" />
        </>
      )}
      {game && (
        <>
          <Typography mt={1.5} color="#000000DE" fontWeight="700" fontSize="14px" lineHeight="22px" letterSpacing="0.1px">
            [{t('document.game')}] {t('document.categories.title')}
          </Typography>
          <CategoriesRow type="game" />
        </>
      )}
      {lecture && <CategoriesRow type="lecture" />}
    </Box>
  );
};
