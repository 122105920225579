import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { TwinkleSaleContainer } from '../index.styles';
import { Box, Button, Typography } from '@mui/material';
import TwinkleDiscountList from '../components/TwinkleDiscountList/TwinkleDiscountList';
import palette from 'theme/palette';
import Calendar from 'acon-mui/components/Calendar/Calendar';
import { useBrandPromotionQuery, useCancelBrandPromotionMutation } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import TwinkleModal from '../components/TwinkleModal/TwinkleModal';
import { FormProvider, useForm } from 'react-hook-form';
import { IProduct } from '../components/TwinkleDiscountList/TwinkleDiscountList.types';
import { TwinkleSaleWriteFooter } from '../write/index.styles';
import { useTwinkleSaleStore } from 'stores/promotions/twinkle-sale/useTwinkleSaleStore';
const getTwinkleSaleStore = (state) => ({ status: state.status, setToastStatus: state.setToastStatus });

export default function (props) {
  const {
    history,
    match: { params },
  } = props;
  const { t } = useTranslation();
  const [isShowCancelModal, setIsShowCancelModal] = useState(false);

  const { status, setToastStatus } = useTwinkleSaleStore(getTwinkleSaleStore);

  const { data: brandPromotionData, loading } = useBrandPromotionQuery({ variables: { brandPromotionId: Number(params?.id) } });
  const brandPromotion = brandPromotionData?.brandPromotion || null;

  const isBefore24Hours = useMemo(() => dayjs(brandPromotion?.promotion.since).subtract(24, 'hour').isBefore(), [brandPromotion]);

  const methods = useForm<{ products: IProduct[] }>({
    defaultValues: { products: [] },
    mode: 'onChange',
  });

  const [cancelBrandPromotion] = useCancelBrandPromotionMutation({
    onCompleted: () => {
      setToastStatus('cancel');
      history.push('/manager/promotion/twinkle-sale');
    },
    onError: () => {},
  });

  const handleOnCancel = async () => {
    try {
      if (isBefore24Hours) return;
      await cancelBrandPromotion({ variables: { input: { id: Number(params?.id) } } });
      history.push('/manager/promotion/twinkle-sale');
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    if (brandPromotion) {
      const productItem = brandPromotion?.items.map((item) => ({ ...item, ...item.asset, assetId: item.asset.id }));
      productItem.forEach((item) => delete item.asset);
      methods.reset({ products: productItem });
    }
  }, [brandPromotion, methods]);

  if (!status) history.push('/manager/promotion/twinkle-sale');
  if (loading || !brandPromotion) return <></>;

  return (
    <FormProvider {...methods}>
      <TwinkleModal status="cancel" isShow={isShowCancelModal} onClose={() => setIsShowCancelModal(false)} onSuccess={handleOnCancel} />
      <TwinkleSaleContainer fullWidth>
        <Typography variant="h4" color={palette.light.text.primary} pt={1} pb={2}>
          {t('twinkleSalePage.applicationDetails')}
        </Typography>
        <Calendar since={brandPromotion?.promotion?.since} until={brandPromotion?.promotion.until} status={status} isShowTooltip={false} />
        <Typography variant="h5" color={palette.light.text.primary} pt={8} pb={2}>
          {t('twinkleSalePage.discountedGoods')}
        </Typography>
        <TwinkleDiscountList type="view" />
        {status === 'ready' && (
          <TwinkleSaleWriteFooter>
            <Box display="flex" justifyContent="flex-end" alignItems="center" p="12px" maxWidth="1060px" m="0 auto" pr="0">
              <Button
                color="error"
                size="medium"
                variant="contained"
                sx={{ minWidth: '129px', whiteSpace: 'pre' }}
                disabled={isBefore24Hours}
                onClick={isBefore24Hours ? null : () => setIsShowCancelModal(true)}
              >
                {t('twinkleSalePage.CancelApplication')}
              </Button>
            </Box>
          </TwinkleSaleWriteFooter>
        )}
      </TwinkleSaleContainer>
    </FormProvider>
  );
}
