import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { useCategoryTreeStore, useDocumentStore } from 'boards/DetailBoardWrite/stores';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  type: 'genre' | 'theme' | 'game' | 'lecture';
}

export default function CategoriesRow({ type }: Props) {
  const { t } = useTranslation();
  const { isDisp, categories, setCategories } = useDocumentStore((state) => ({
    isDisp: state.isDisp,
    categories: state.categories,
    setCategories: state.setCategories,
  }));
  const { categoryTree } = useCategoryTreeStore((state) => ({ categoryTree: state.categoryTree }));

  const primaryVal = categories[type]?.categoryBranch?.[0];
  const secondaryVal = categories[type]?.categoryBranch?.[1];
  const tertiaryVal = categories[type]?.categoryBranch?.[2];

  const primaryOptions = categoryTree[type] && categoryTree[type][0]?.children;
  const secondaryOptions = useMemo(() => {
    if (primaryVal) {
      return primaryOptions?.find((x) => x.value.code === primaryVal)?.children;
    }
    return undefined;
  }, [primaryVal, primaryOptions]);
  const tertiaryOptions = useMemo(() => {
    if (secondaryVal) {
      return secondaryOptions?.find((x) => x.value.code === secondaryVal)?.children;
    }
    return undefined;
  }, [secondaryVal, secondaryOptions]);

  const handleChangeCategory = (event: SelectChangeEvent<string>, order: 'primary' | 'secondary' | 'tertiary') => {
    let newCategories = [event.target.value];
    if (order === 'primary') {
      if (type === 'lecture') {
        newCategories = [event.target.value];
      } else {
        newCategories = [event.target.value, undefined];
      }
    } else if (order === 'secondary') {
      if (tertiaryOptions && tertiaryOptions.length > 0) {
        newCategories = [primaryVal, event.target.value, undefined];
      } else {
        newCategories = [primaryVal, event.target.value];
      }
    } else {
      newCategories = [primaryVal, secondaryVal, event.target.value];
    }
    setCategories({
      ...categories,
      [type]: {
        categoryBranch: newCategories,
      },
    });
  };

  if (type === 'lecture') {
    return (
      <Box mt={1.5} gap={1.5} display="flex" alignItems="center">
        <Typography color="#000000DE" fontWeight="700" fontSize="14px" lineHeight="22px" letterSpacing="0.1px">
          [{t('document.lecture')}] {t('document.categories.title')}
        </Typography>
        <FormControl
          size="small"
          sx={{
            width: '100%',
            maxWidth: '220px',
            '& label.Mui-focused': { display: 'none' },
            ...(primaryVal && { '& label': { display: 'none' } }),
          }}
        >
          <InputLabel disableAnimation={false}>{t('document.categories.primary')}</InputLabel>
          <Select value={primaryVal || ''} disabled={isDisp} onChange={(e) => handleChangeCategory(e, 'primary')} sx={{ borderRadius: 0.5 }}>
            <MenuItem value={''} disabled sx={{ display: 'none' }} />
            {primaryOptions?.map((x) => (
              <MenuItem key={x.value.id} value={x.value.code}>
                {x.value.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    );
  }

  return (
    <Box mt={1.5} display="grid" gridTemplateColumns="50px 1fr 1fr 1fr" gap="12px" alignItems="center">
      <Typography width="50px" fontWeight="500" fontSize="14px" lineHeight="22px" letterSpacing="0.1px" color="#000000DE">
        {type === 'genre' && t('document.categories.genre')}
        {type === 'theme' && t('document.categories.subject')}
      </Typography>
      <FormControl
        size="small"
        sx={{
          maxWidth: '220px',
          '& label.Mui-focused': { display: 'none' },
          ...(primaryVal && { '& label': { display: 'none' } }),
        }}
      >
        <InputLabel disableAnimation={false}>{t('document.categories.primary')}</InputLabel>
        <Select value={primaryVal || ''} disabled={isDisp} onChange={(e) => handleChangeCategory(e, 'primary')} sx={{ borderRadius: 0.5 }}>
          <MenuItem value={''} disabled sx={{ display: 'none' }} />
          {primaryOptions?.map((x) => (
            <MenuItem key={x.value.id} value={x.value.code}>
              {x.value.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl
        size="small"
        sx={{
          maxWidth: '220px',
          '& label.Mui-focused': { display: 'none' },
          ...(secondaryVal && { '& label': { display: 'none' } }),
        }}
      >
        <InputLabel disableAnimation={false}>{t('document.categories.secondary')}</InputLabel>
        <Select value={secondaryVal || ''} disabled={isDisp || !primaryVal} onChange={(e) => handleChangeCategory(e, 'secondary')} sx={{ borderRadius: 0.5 }}>
          <MenuItem value={''} disabled sx={{ display: 'none' }} />
          {secondaryOptions?.map((x) => (
            <MenuItem key={x.value.id} value={x.value.code}>
              {x.value.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {tertiaryOptions && tertiaryOptions.length > 0 && (
        <FormControl
          size="small"
          sx={{
            maxWidth: '220px',
            '& label.Mui-focused': { display: 'none' },
            ...(tertiaryVal && { '& label': { display: 'none' } }),
          }}
        >
          <InputLabel disableAnimation={false}>{t('document.categories.tertiary')}</InputLabel>
          <Select value={tertiaryVal || ''} disabled={isDisp} onChange={(e) => handleChangeCategory(e, 'tertiary')} sx={{ borderRadius: 0.5 }}>
            <MenuItem value={''} disabled sx={{ display: 'none' }} />
            {tertiaryOptions?.map((x) => (
              <MenuItem key={x.value.id} value={x.value.code}>
                {x.value.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Box>
  );
}
