import { UnderButton } from '../../component/form';
import React, { useContext, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fileController } from 'api';
import Loading from 'common/editor/Loading';
import JSZip from 'jszip';
import iconv from 'iconv-lite';
import { useQuery } from '@apollo/client';
import { GET_EXTENSION_CONFIGS } from '../../../../api/quries';
import { AppContext } from 'app';
import { Box } from '@mui/material';
import { File, FileUpload, Title } from 'acon-mui/components/Board';
import { STATUS_DRAFT_ON_OPEN } from 'boards/DetailBoardWrite/constants';
import { useHubDownloadQuery } from 'generated/graphql';
import { useDocumentStore, useOriginalDocumentStore, useSelectedFieldsStore } from 'boards/DetailBoardWrite/stores';
import { FileStatus } from 'boards/DetailBoardWrite/stores/document.types';

type Props = {
  setIsInProgress?: (val: boolean) => void;
  isAccept: boolean;
  isAdmin?: boolean;
};

export default ({ setIsInProgress = (val: boolean) => {}, isAccept, isAdmin = false }: Props) => {
  // 번역도구
  const { t } = useTranslation();

  const { showAlertMessage } = useContext(AppContext);
  const { isDisp, documentStatus, file, setFile } = useDocumentStore((state) => ({
    isDisp: state.isDisp,
    documentStatus: state.status,
    file: state.file,
    setFile: state.setFile,
  }));
  const originalData = useOriginalDocumentStore((state) => ({
    id: state.id,
    file: state.file,
  }));
  const { selectedFields, setSelectedFields } = useSelectedFieldsStore();
  const [status, setStatus] = useState<FileStatus>(null);
  const [progress, setProgress] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const isCheckboxVisible = useMemo(() => [STATUS_DRAFT_ON_OPEN].includes(documentStatus), [documentStatus]);
  const disabled = useMemo(() => (isCheckboxVisible && !isChecked) || isDisp, [isCheckboxVisible, isChecked, isDisp]);

  const [isShowLoading, setIsShowLoading] = useState(false);
  const { data: extensionConfigData, loading } = useQuery(GET_EXTENSION_CONFIGS, { skip: !isAdmin });

  // 확장자 리스트 정보 로딩여부 판단.
  const isLoading = !(!loading && extensionConfigData && extensionConfigData.getExtensionConfigs);
  const extensions = (() => {
    if (isLoading) return [];
    return extensionConfigData.getExtensionConfigs.map((x) => {
      return {
        value: x.id,
        label: x.name,
      };
    });
  })();

  // 업로더
  const uploader = new fileController();
  // 파일 태그
  const fileTag = useRef<HTMLInputElement>(null);

  const onClick = () => {
    // 파일 태그 클릭
    fileTag.current.click();
  };
  // 파일 변경 이벤트 처리기 메소드
  const onChangeFile = async () => {
    // 파일
    const inputFile = fileTag.current?.files[0];
    if (inputFile.type.indexOf('zip') === -1) {
      showAlertMessage('', t('goods.modelDescription'), { confirmText: t('cancelConfirm') });
      return;
    }
    setFile({
      ...file,
      fileName: inputFile.name,
    });
    // 진행중
    setStatus('inprogress');
    setIsInProgress(true);

    const { key } = await uploader.upload(inputFile, (count) => {
      setProgress(count);

      // 완료된 경우
      if (count === 100) {
        // 진행 종료
        setStatus('success');
        setIsInProgress(false);
      }
    });
    const fileName = inputFile.name.replace(/\.[a-z0-9]{3,}$/, '');
    setFile({
      ...file,
      filePath: key,
      fileName: fileName.replace('.zip', '') + '.zip',
    });

    if (isAdmin) {
      await setModelFilesInZip(file, extensions);
    }
  };

  const setModelFilesInZip = async (file, extensionList) => {
    try {
      if (!file) return;
      else if (file.type.indexOf('zip') === -1) return;
      setIsShowLoading(true);

      const fileConfigs = [];
      const extensions = [];

      const zipFile = await JSZip.loadAsync(file, {
        decodeFileName: (bytes) => iconv.decode(bytes as Buffer, 'euc-kr'),
      });

      for (const fileName in zipFile.files) {
        if (zipFile.files[fileName].dir) continue;
        // 확장자 리스트에 포함되어있는지 체크하여 파일구성 불러오도록.
        const extension = '.' + fileName.split('.').pop().toLowerCase();
        const extensionId = extensionList.find((x) => x.label === extension)?.value;
        if (!extensionId) {
          continue;
        }

        // 폴더 경로 제거하고 파일명만 불러오도록 처리.
        // 확장자 소문자로 불러와야하여 확장자 제거 후 파일명 수집
        const fileNameWithoutExtension = fileName.split('/').pop().split('.').shift();
        const fileArrayBuffer = await (zipFile.file(fileName) as any).async('ArrayBuffer');
        const fileSize = Math.round((fileArrayBuffer.byteLength / 1024 / 1000) * 10) / 10;
        fileConfigs.push({
          id: -1,
          name: fileNameWithoutExtension + extension,
          size: fileSize < 1 ? 1 : fileSize,
        });

        extensions.push(extensionId);
      }

      setIsShowLoading(false);
    } catch (err) {
      showAlertMessage('', t('goods.autoFillExtentionAlert'), {
        confirmText: t('cancelConfirm'),
      });
      setIsShowLoading(false);
    }
  };

  const { data: hubDownload } = useHubDownloadQuery({
    variables: {
      hubDownloadId: originalData?.id,
    },
    skip: !originalData.id,
    fetchPolicy: 'no-cache',
  });

  // 다운로드 버튼 클릭 이벤트 처리기 메소드
  const onClickDownloadButtonTag = () => {
    window.open(hubDownload.hubDownload, '_blank');
  };
  const handleChecked = (checked, val) => {
    setIsChecked(checked);
    if (checked) {
      setSelectedFields([...selectedFields, val]);
    } else {
      setFile(originalData?.file);
      setSelectedFields(selectedFields.filter((item) => item !== val));
    }
  };

  return (
    <>
      {isShowLoading && <Loading />}
      <Box>
        <Box display="flex" alignItems="center" marginBottom="12px">
          <Title checkboxVisible={isCheckboxVisible} isChecked={isChecked} onClick={(value, checked) => handleChecked(checked, t('goods.model'))}>
            {t('goods.model')}
          </Title>
          {file.filePath ? (
            <UnderButton style={{ pointerEvents: 'all' }} onClick={onClickDownloadButtonTag}>
              {t('download')}
            </UnderButton>
          ) : (
            <></>
          )}
        </Box>

        <Box sx={{ ...(disabled && { pointerEvents: 'none' }) }}>
          <FileUpload description={t('document.file.uploadDescription')} onChange={onChangeFile} onClick={onClick} ref={fileTag} />

          {file.fileName && (
            <File
              status={status}
              fileName={`${file.fileName.replace('.zip', '')}.zip`}
              progress={status === 'success' && file.fileName ? 100 : progress}
              fileSize={fileTag.current?.files[0]?.size}
              onDelete={() =>
                setFile({
                  fileName: null,
                  filePath: null,
                })
              }
              marginTop="48px"
            />
          )}
        </Box>
      </Box>
    </>
  );
};
