import { createWithEqualityFn } from 'zustand/traditional';
import { CategoryTree, recursiveTreeFunc } from './categoryTree.class';
import { CategoryNode } from 'generated/graphql';
import { shallow } from 'zustand/shallow';

export interface ICategoryTreeStore {
  categoryTree: {
    genre?: CategoryTree[];
    theme?: CategoryTree[];
    game?: CategoryTree[];
    lecture?: CategoryTree[];
  };
  setCategoryTree: (categoryNodes: { genre?: CategoryNode[]; theme?: CategoryNode[]; game?: CategoryNode[]; lecture?: CategoryNode[] }) => void;
}

export const useCategoryTreeStore = createWithEqualityFn<ICategoryTreeStore>((set) => {
  return {
    categoryTree: {
      genre: undefined,
      theme: undefined,
      game: undefined,
      lecture: undefined,
    },
    setCategoryTree: (categoryNodes) => {
      const categories: (keyof typeof categoryNodes)[] = ['genre', 'theme', 'game', 'lecture'];
      const resultTree: Partial<ICategoryTreeStore['categoryTree']> = {};

      for (const category of categories) {
        const list = categoryNodes[category];
        const node: CategoryTree[] = [];

        if (list) {
          for (const ele of list) {
            if (ele.parent) {
              recursiveTreeFunc(node, ele);
            } else {
              node.push(new CategoryTree(ele));
            }
          }
        }

        resultTree[category] = node;
      }

      set(() => ({
        categoryTree: resultTree,
      }));
    },
  };
}, shallow);
