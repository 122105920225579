import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import 'i18n/i18n';
// Routing에 사용되는 컴포넌트들
import Home from '../../Home';
import SignIn from '../../signin/SignIn';
import ChangePw from '../../password/ChangePw';
import SearchPw from '../../password/SearchPw';
import NoMatch from '../../exception/NoMatch';
import BoardsWrite from '../../boards/BoardsWrite';
import DetailBoardWrite from '../../boards/DetailBoardWrite';
import Boards from '../../boards/Boards';
import AconFaq from '../../aconfaq';
import { AconFaqWrite } from '../../aconfaq/form';
import UserChange from '../../user/Change';
import Account from '../../account/Account';
import Search from '../../search/search';
import Category from '../../manager/categoryLang/index';
import MainGroup from '../../manager/mainGroup';
import DetailChange from '../../manager/detailChange';
import Settle from '../../manager/settle';
import SettleDetail from '../../manager/settle/detail';
import Brand from '../../manager/brand';
import Logout from '../../logout/Logout';
import Auth from '../../manager/auth';
import Burden from '../../manager/burden';
import BurdenWrite from '../../manager/burden/write';
import TwinkleSale from 'manager/promotion/twinkle-sale';
import Banner from '../../banner/Banner';
import BannerWrite from '../../banner/write';
import Welcome from 'user/welcome';
import UpdateTerm from 'user/updateTerm';
import UserPlan from 'user/plan';
import download from 'download/download';
import UserPage from 'pages/user';
import UserSettlePage from 'pages/userSettle';
import UserStorePage from 'pages/userStore';
import useAuth from 'hooks/useAuth';
import LoadingScreen from 'components/LoadingScreen';
import Signup from 'pages/signup';
import SignupAdmin from 'pages/admin/AdminSignup';
import BoardContent from 'boards/BoardContent';
import BrandPage from 'manager/brand/brand-page';
import TwinkleDetail from 'manager/promotion/twinkle-sale/detail';
import TwinkleWrite from 'manager/promotion/twinkle-sale/write';

const Route2 = (props) => {
  const Tag = props.tag;

  return <Route exact={props.exact ? props.exact : false} path={props.path} render={(newProps) => <Tag {...newProps} {...props.data} />} />;
};

export const MainRoute = withTranslation()((props) => {
  const { user, isInitialized, isAuthenticated } = useAuth();
  let result = <></>;

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  // 로그인하지 않았을 경우의 라우팅 시나리오입니다.
  if (!isAuthenticated) {
    result = (
      <Switch>
        {/* 로그아웃 페이지 */}
        <Route2 exact path="/logout" data={props} tag={Logout} />
        {/* 로그인 페이지 */}
        <Route2 exact path="/signin" data={props} tag={SignIn} />
        {/* 회원가입 페이지 */}
        <Route2 exact path={['/signup/en', '/signup/ko']} data={props} tag={Signup} />
        {/* 비밀번호 찾기 페이지 */}
        <Route2 exact path="/password/searchPw" data={props} tag={SearchPw} />
        {/* 비밀번호 변경하기 페이지 */}
        <Route2 exact path={['/password/changePw/:key', '/password/changePw/']} data={props} tag={ChangePw} />
        {/* 그 외 로그인 권한이 필요한 경우에는 로그인페이지로 리디렉팅 */}
        <Route path={['/', '/goods/write', '/goods', '/board/notice/write', '/board/notice', '/user/:no', '/account']}>
          <Redirect to="/signin" />
        </Route>
        {/* 미설정된 경로 */}
        <Route component={NoMatch} />
      </Switch>
    );
  } else if (user.acceptStatus === -1 && !user.isAdmin) {
    result = (
      <Switch>
        <Route2 exact path="/logout" data={props} tag={Logout} />
        <Route2 exact path="/" data={props} tag={UserStorePage} />
      </Switch>
    );
  } else if (user.isUpdateTerm && !user.isAdmin) {
    // 로그인되었을 경우의 라우팅 시나리오입니다.
    // 계약서 갱신 대상 (관리자 제외)
    result = (
      <Switch>
        <Route2 exact path="/logout" data={props} tag={Logout} />
        <Route2 path="/" data={props} tag={UpdateTerm} />
      </Switch>
    );
  } else if (user.hasntPlan && user.isAccept && !user.isAdmin) {
    // 파트너십이 없는 승인된 사용자 (관리자 제외)
    result = (
      <Switch>
        <Route2 exact path="/logout" data={props} tag={Logout} />
        <Route2 path="/" data={props} tag={UserPlan} />
      </Switch>
    );
  } else {
    result = (
      <Switch>
        {/* 로그아웃 페이지 */}
        <Route2 exact path="/logout" data={props} tag={Logout} />
        {/* 제품상세 설명글 작성 페이지 */}
        <Route2 exact data={props} tag={DetailBoardWrite} path={['/goods/write/:lang', '/goods/write/:lang/:docId', '/goods/write/:lang/:docId/:type(open)']} />
        {/* (공지 등) 게시글 작성 페이지 */}
        <Route2 exact path={['/board/:boardName/write', '/board/:boardName/write/:docId']} data={props} tag={BoardsWrite} />
        {/* (공지 등) 게시판 페이지 */}
        <Route2 exact path={['/board/:boardName', '/board/:boardName/:page']} data={props} tag={Boards} />
        {/* (공지 등) 게시판 글 상세 페이지 */}

        <Route2 exact path={['/board/:boardName/detail/:id']} data={props} tag={BoardContent} />
        {/* 에이콘 FAQ 게시글 작성 페이지 */}
        <Route2 exact path={['/aconfaq/:name/write', '/aconfaq/:name/write/:docId']} data={props} tag={AconFaqWrite} />
        {/* FAQ 게시판 페이지 */}
        <Route2 exact path={['/aconfaq/:name', '/aconfaq/:name/:page']} data={props} tag={AconFaq} />
        {/* 회원가입 페이지 */}
        <Route2 exact path={['/signup/en', '/signup/ko']} data={props} tag={Signup} />
        <Route2 exact path="/signup/manual" data={props} tag={SignupAdmin} />
        {/* 비밀번호 변경 페이지 */}
        <Route2 exact path={['/password/changePw']} data={props} tag={ChangePw} />
        {/* 회원정보 변경 페이지 */}
        <Route2 exact path={['/user/:no', '/user', '/user/']} data={props} tag={UserPage} />
        <Route2 exact path={['/userSettle']} data={props} tag={UserSettlePage} />
        <Route2 exact path={['/userStore']} data={props} tag={UserStorePage} />
        {/* 계정관리 페이지 */}
        <Route2 exact path={['/account/:page', '/account']} data={props} tag={Account} />
        {/* 혜택 관리 페이지*/}
        <Route2 exact path={['/manager/benefit/:displayLocation']} data={props} tag={Burden} />
        {/* 혜택 생성 페이지 */}
        <Route2 exact path={['/manager/benefit/:displayLocation/write', '/manager/benefit/:displayLocation/write/:docId']} data={props} tag={BurdenWrite} />
        {/* 카테고리 다국어 관리 페이지 */}
        <Route2 exact path="/manager/categoryLang" data={props} tag={Category} />
        {/* 메인상품진열 다국어 관리 페이지 */}
        <Route2 exact path="/manager/mainGroup" data={props} tag={MainGroup} />
        {/* 특정문장 일괄 변경 페이지 */}
        <Route2 exact path="/manager/detailChange" data={props} tag={DetailChange} />
        {/* 판매내역 페이지 */}
        <Route2 exact path="/manager/settle" data={props} tag={Settle} />
        {/* 판매내역 상세 페이지 */}
        <Route2 exact path="/manager/settle/:orderNo/:goodsNo" data={props} tag={SettleDetail} />
        {/* 브랜드코드 관리 페이지 */}
        <Route2 exact path="/manager/brand" data={props} tag={Brand} />
        <Route2 exact path="/manager/brand/brand-page" data={props} tag={BrandPage} />
        {/* 프로모션 관리 페이지 */}
        <Route2 exact path="/manager/promotion/twinkle-sale" data={props} tag={TwinkleSale} />
        <Route2 exact path="/manager/promotion/twinkle-sale/detail/:id" data={props} tag={TwinkleDetail} />
        <Route2 exact path="/manager/promotion/twinkle-sale/write/:conceptId" data={props} tag={TwinkleWrite} />
        {/* 권한 관리 페이지 */}
        <Route2 exact path="/manager/auth" tag={Auth} />
        {/* 인기검색어 관리 페이지 */}
        <Route2 exact path="/searchKeyword" data={props} tag={Search} />
        {/* 배너 작성 페이지 */}
        <Route2 exact path={['/banner/:displayLocation/write', '/banner/:displayLocation/write/:docId']} data={props} tag={BannerWrite} />
        {/* 배너 관리 페이지 */}
        <Route2 exact path={['/banner/:displayLocation', '/banner/:displayLocation/:page']} data={props} tag={Banner} />
        {/* 다운로드 관리 페이지 */}
        <Route2 exact path="/download" data={props} tag={download} />
        {/* 랜딩 페이지 */}
        <Route2 exact path={['/']} data={props} tag={Home} />
        {/* 로그인 페이지; 이미 로그인 되었으므로 랜딩 페이지로 이동 */}
        <Route exact path={['/signin']}>
          <Redirect to="/" />
        </Route>
        {/* 사용자 그룹 - user */}
        {!user.isAdmin && (
          <Switch>
            {!user.isAccept && (
              // 사용자 그룹 - unapproved
              <Route2 exact path="/welcome" data={props} tag={Welcome} />
            )}

            {user.acceptStatus !== -1 && (
              // 사용자 상태 - 요청, 승인
              <Route2 exact path="/userChange" data={props} tag={UserChange} />
            )}
          </Switch>
        )}
        {/* 미설정된 경로 */}
        <Route component={NoMatch} />
      </Switch>
    );
  }
  return (
    <Switch>
      {/* 전역 라우팅 */}
      <Route exact path="/partnership" render={() => <UserPlan isPublic />} />
      <Route2 exact path="/en" data={{ ...props, isEn: true }} tag={isAuthenticated ? Home : SignIn} />
      {/* 목표에 맞게 설정된 라우팅 */}
      {result}
    </Switch>
  );
});
