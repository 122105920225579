import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from 'acon-mui/components/Board';
import { Box, TextField } from '@mui/material';
import { Button } from 'acon-mui/components';
import { Typography } from '@mui/material';
import { STATUS_DRAFT_ON_OPEN } from 'boards/DetailBoardWrite/constants';
import { useDocumentStore, useOriginalDocumentStore, useSelectedFieldsStore } from 'boards/DetailBoardWrite/stores';

export default () => {
  // 번역도구
  const { t } = useTranslation();
  const { isDisp, status, keywords, setKeywords } = useDocumentStore((state) => ({
    isDisp: state.isDisp,
    status: state.status,
    keywords: state.keywords,
    setKeywords: state.setKeywords,
  }));
  const originalData = useOriginalDocumentStore((state) => ({
    keywords: state.keywords,
  }));
  const { selectedFields, setSelectedFields } = useSelectedFieldsStore();
  const [input, setInput] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const isCheckboxVisible = useMemo(() => [STATUS_DRAFT_ON_OPEN].includes(status), [status]);
  const disabled = useMemo(() => (isCheckboxVisible && !isChecked) || isDisp, [isCheckboxVisible, isDisp, isChecked]);

  // input 태그 변경 이벤트 처리기 메소드
  const onChangeInput = (e) => {
    if (e.target.value.indexOf('\n') > -1 || e.target.value.includes(' ')) return false;
    setInput(e.target.value);
  };
  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && e.nativeEvent.isComposing === false) {
      handleAddKeyword();
      return false;
    }
  };

  const handleAddKeyword = () => {
    if (input === null || input === '' || input === ' ') return;
    if (!keywords.includes(input) && keywords.length < 10) {
      setKeywords([...keywords, input]);
    }
    setInput('');
  };

  const handleChecked = (checked, val) => {
    setIsChecked(checked);
    if (checked) {
      setSelectedFields([...selectedFields, val]);
    } else {
      setKeywords(originalData?.keywords);
      setSelectedFields(selectedFields.filter((item) => item !== val));
    }
  };

  return (
    <Box>
      <Title checkboxVisible={isCheckboxVisible} isChecked={isChecked} onClick={(value, checked) => handleChecked(checked, t('document.keywords.title'))}>
        {t('document.keywords.title')}
      </Title>
      <Box display="flex" mt="12px">
        <TextField
          onKeyDown={onKeyDown}
          disabled={disabled}
          placeholder={t('document.keywords.placeholder')}
          onChange={onChangeInput}
          value={input || ''}
          inputProps={{ maxLength: 255 }}
          fullWidth
          size="small"
        />
        <Button
          onClick={handleAddKeyword}
          disabled={disabled}
          width="90px"
          colorTheme="primary"
          variant="contained"
          marginLeft="8px"
          fontWeight="500"
          fontSize="14px"
          lineHeight="24px"
          borderRadius="4px"
        >
          {t('document.keywords.add')}
        </Button>
      </Box>
      <Box display="flex" flexWrap="wrap">
        {keywords.map((keyword, i) => (
          <Box key={`${keyword}-${i}`} mt={1} mr={1} p="3px 5px 3px 10px" display="flex" alignItems="center" borderRadius="99px" bgcolor="#00000014">
            <Typography mr={1} color="#000000DE" fontSize="13px" lineHeight="18px" letterSpacing="0.16px">
              {keyword}
            </Typography>
            <Box
              {...(!disabled && {
                onClick: () => {
                  const newKeywords = keywords.filter((x) => x !== keyword);
                  setKeywords(newKeywords);
                },
                sx: { cursor: 'pointer' },
              })}
              display="inherit"
            >
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6.99998 0.333252C3.31331 0.333252 0.333313 3.31325 0.333313 6.99992C0.333313 10.6866 3.31331 13.6666 6.99998 13.6666C10.6866 13.6666 13.6666 10.6866 13.6666 6.99992C13.6666 3.31325 10.6866 0.333252 6.99998 0.333252ZM10.3333 9.39325L9.39331 10.3333L6.99998 7.93992L4.60665 10.3333L3.66665 9.39325L6.05998 6.99992L3.66665 4.60659L4.60665 3.66659L6.99998 6.05992L9.39331 3.66659L10.3333 4.60659L7.93998 6.99992L10.3333 9.39325Z"
                  fill="black"
                  fillOpacity="0.23"
                />
              </svg>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
