import { createWithEqualityFn } from 'zustand/traditional';
import { IDocumentSetState, IDocumentState, TSetterKeys } from './document.types';
import { initialDocumentState, languageList } from './document.constants';
import {
  NEW_STATUS_DRAFT,
  STATUS_COMPLETE,
  STATUS_COMPLETE_ON_OPEN,
  STATUS_DENIED,
  STATUS_DRAFT_ON_OPEN,
  STATUS_IN_REVIEW,
  STATUS_IN_REVIEW_ON_OPEN,
  STATUS_ON_SALE,
  STATUS_ON_SALE_ON_OPEN,
  STATUS_REJECTED,
  STATUS_REJECTED_ON_OPEN,
  STATUS_REQUESTED,
  STATUS_REQUESTED_ON_OPEN,
} from 'boards/DetailBoardWrite/constants';
import { shallow } from 'zustand/shallow';

export const useDocumentStore = createWithEqualityFn<IDocumentState & IDocumentSetState>((set) => {
  const initialDocumentSetState = {} as IDocumentSetState;

  for (const key in initialDocumentState) {
    const keyName = `set${key[0].toUpperCase()}${key.slice(1)}` as TSetterKeys;
    initialDocumentSetState[keyName] = (state) => set(() => ({ [key]: state }));
  }
  return {
    ...initialDocumentState,
    ...initialDocumentSetState,
    setFile: (file) =>
      set((state) => ({
        file: {
          ...state.file,
          ...((file?.filePath || file?.filePath === null) && { filePath: file?.filePath }),
          ...((file?.fileName || file?.fileName === null) && { fileName: file?.fileName }),
        },
      })),
    setMainImage: (mainImage) => set((state) => ({ mainImage: { ...state.mainImage, ...mainImage } })),
    setSubImage: (subImage) => set((state) => ({ subImage: { ...state.subImage, ...subImage } })),
    setDocumentData: (documentData) =>
      set((state) => {
        const {
          id,
          assetId,
          brand,
          language,
          originalLanguage,
          title,
          contentHead,
          contentBody,
          file,
          extensions,
          applications,
          mainImage,
          subImage,
          categories,
          price,
          keywords,
          copyright,
          status,
          memo,
          created,
          requested,
          completed,
          version,
          priceChanged,
          isAdultOnly,
          webtoon,
          game,
          lecture,
          imgsrc,
          message,
        } = documentData;

        const isEdit = [
          STATUS_REJECTED,
          STATUS_REJECTED_ON_OPEN,
          STATUS_DRAFT_ON_OPEN,
          STATUS_REQUESTED_ON_OPEN,
          STATUS_IN_REVIEW_ON_OPEN,
          STATUS_COMPLETE_ON_OPEN,
          STATUS_ON_SALE_ON_OPEN,
        ].includes(status);

        return {
          ...(typeof id === 'string' && { id }),
          ...(typeof assetId === 'number' && { assetId }),
          ...(typeof brand === 'number' && { brand }),
          ...(languageList.includes(language) && {
            language,
          }),
          ...(languageList.includes(originalLanguage) && {
            originalLanguage,
          }),
          ...(typeof title === 'string' && { title }),
          ...(typeof contentHead === 'string' && { contentHead }),
          ...(typeof contentBody === 'string' && { contentBody }),
          ...(file && { file }),
          ...(Array.isArray(extensions) && { extensions }),
          ...(Array.isArray(applications) && { applications }),
          mainImage: {
            ...state.mainImage,
            ...mainImage,
          },
          subImage: {
            ...state.subImage,
            ...subImage,
          },
          categories: {
            genre: {
              categoryBranch: categories?.genre?.categoryBranch,
            },
            theme: {
              categoryBranch: categories?.theme?.categoryBranch,
            },
            game: {
              categoryBranch: categories?.game?.categoryBranch,
            },
            lecture: {
              categoryBranch: categories?.lecture?.categoryBranch,
            },
          },
          ...(typeof price === 'number' && { price }),
          ...(Array.isArray(keywords) && { keywords }),
          ...(typeof copyright?.isOriginal === 'boolean' && { copyright }),
          ...([
            NEW_STATUS_DRAFT,
            STATUS_REQUESTED,
            STATUS_IN_REVIEW,
            STATUS_DENIED,
            STATUS_REJECTED,
            STATUS_REJECTED_ON_OPEN,
            STATUS_COMPLETE,
            STATUS_DRAFT_ON_OPEN,
            STATUS_REQUESTED_ON_OPEN,
            STATUS_IN_REVIEW_ON_OPEN,
            STATUS_COMPLETE_ON_OPEN,
            STATUS_ON_SALE,
            STATUS_ON_SALE_ON_OPEN,
          ].includes(status) && { status }),
          ...(memo && { memo }),
          ...(created && { created }),
          ...(requested && { requested }),
          ...(completed && { completed }),
          ...(typeof version === 'number' && { version }),
          isEdit,
          isDisp: ![NEW_STATUS_DRAFT, STATUS_DRAFT_ON_OPEN].includes(status),
          priceChanged,
          ...(typeof isAdultOnly === 'boolean' && { isAdultOnly }),
          ...(typeof webtoon === 'boolean' && { webtoon }),
          ...(typeof game === 'boolean' && { game }),
          ...(typeof lecture === 'boolean' && { lecture }),
          ...(typeof imgsrc === 'boolean' && { imgsrc }),
          ...(typeof message === 'string' && { message }),
        };
      }),
    resetDocumentData: () => set(() => initialDocumentState),
  };
}, shallow);
