import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Box, Switch, TextField, Typography } from '@mui/material';
import { Field, Title } from 'acon-mui/components/Board';
import InformationCard from 'acon-mui/components/Board/InformationCard';
import { STATUS_DRAFT_ON_OPEN } from 'boards/DetailBoardWrite/constants';
import parse from 'html-react-parser';
import { AppContext } from 'app';
import { useDocumentStore, useOriginalDocumentStore, useSelectedFieldsStore } from 'boards/DetailBoardWrite/stores';

export default () => {
  const { docId } = useParams<{ docId?: string }>();
  const { showAlertMessage } = useContext(AppContext);
  const { t, i18n } = useTranslation();
  const { isDisp, status, title, setTitle, isAdultOnly, setIsAdultOnly, lecture } = useDocumentStore((state) => ({
    isDisp: state.isDisp,
    status: state.status,
    title: state.title,
    setTitle: state.setTitle,
    isAdultOnly: state.isAdultOnly,
    setIsAdultOnly: state.setIsAdultOnly,
    lecture: state.lecture,
  }));
  const originalData = useOriginalDocumentStore((state) => ({
    title: state.title,
    isAdultOnly: state.isAdultOnly,
  }));
  const { selectedFields, setSelectedFields } = useSelectedFieldsStore();
  const [isChecked, setIsChecked] = useState(false);
  const isCheckboxVisible = useMemo(() => [STATUS_DRAFT_ON_OPEN].includes(status), [status]);
  const disabled = useMemo(() => (isCheckboxVisible && !isChecked) || isDisp, [isCheckboxVisible, isDisp, isChecked]);

  // input 태그 변경 이벤트 처리기 메소드
  const onChangeInput = (e) => {
    if (e.target.value.indexOf('\n') > -1) return false;

    setTitle(e.target.value);
  };
  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      return false;
    }
  };
  const handleChecked = (checked, val) => {
    setIsChecked(checked);
    if (checked) {
      setSelectedFields([...selectedFields, val]);
    } else {
      setTitle(originalData?.title);
      setIsAdultOnly(originalData?.isAdultOnly);
      setSelectedFields(selectedFields.filter((item) => item !== val));
    }
  };

  const handleSwitchAdult = (e, val) => {
    if (e.target.checked) {
      showAlertMessage(t('document.adult.modalTitle'), t('document.adult.modalDescription'), () => {});
      setSelectedFields([...selectedFields, val]);
    } else {
      setSelectedFields(selectedFields.filter((item) => item !== val));
    }
    setIsAdultOnly(e.target.checked);
  };

  return (
    <Field
      className="hubWrite_field_title"
      mt={6}
      informationCards={
        <Box position="absolute">
          {isAdultOnly && (
            <InformationCard
              type="notice"
              mb="20px"
              sx={{
                '& a': {
                  color: 'inherit',
                  fontWeight: 600,
                  textDecoration: 'underline',
                },
              }}
            >
              {parse(
                t('document.adult.notice', {
                  href: i18n.language === 'ko' ? '/board/faq/detail/36' : 'https://acon3d.notion.site/ACON-Upload-Guide-8d136c08257d469cb60b78b6c0bdce8e',
                }),
              )}
            </InformationCard>
          )}
          <InformationCard>{t('document.title.information')}</InformationCard>
        </Box>
      }
    >
      <Box display="flex" alignItems="center">
        <Title checkboxVisible={isCheckboxVisible} isChecked={isChecked} onClick={(value, checked) => handleChecked(checked, t('document.title.label'))} marginRight="auto">
          {t('document.title.label')}
        </Title>
        {!lecture && (
          <>
            <Typography fontWeight={500} color="#000000DE" letterSpacing="0.15px">
              {t('document.adult.label')}
            </Typography>
            <Switch
              checked={isAdultOnly}
              disabled={disabled || isCheckboxVisible || lecture}
              onChange={(e) => handleSwitchAdult(e, t('document.adult.label'))}
              sx={{
                '&:not(:.Mui-checked)': {
                  backgroundColor: '#000',
                },
              }}
            />
          </>
        )}
      </Box>
      <TextField
        onKeyDown={onKeyDown}
        disabled={disabled}
        placeholder={t('document.title.placeholder')}
        onChange={onChangeInput}
        value={title}
        fullWidth
        inputProps={{ maxLength: 30 }}
        sx={{ marginTop: '12px' }}
      />
    </Field>
  );
};
