import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { Field, Title } from 'acon-mui/components/Board';
import { STATUS_DRAFT_ON_OPEN } from 'boards/DetailBoardWrite/constants';
import { useDocumentStore } from 'boards/DetailBoardWrite/stores';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default function DocumentType() {
  const { t } = useTranslation();
  const { isDisp, status, webtoon, setWebtoon, game, setGame, lecture, setLecture, imgsrc, setImgsrc, setIsAdultOnly } = useDocumentStore((state) => ({
    isDisp: state.isDisp,
    status: state.status,
    webtoon: state.webtoon,
    setWebtoon: state.setWebtoon,
    game: state.game,
    setGame: state.setGame,
    lecture: state.lecture,
    setLecture: state.setLecture,
    imgsrc: state.imgsrc,
    setImgsrc: state.setImgsrc,
    setIsAdultOnly: state.setIsAdultOnly,
  }));
  const disabled = useMemo(() => isDisp || status === STATUS_DRAFT_ON_OPEN, [isDisp, status]);
  const isWebtoonDisabled = useMemo(() => disabled || lecture || imgsrc, [disabled, lecture, imgsrc]);
  const isGameDisabled = useMemo(() => disabled || lecture || imgsrc, [disabled, lecture, imgsrc]);
  const isLectureDisabled = useMemo(() => disabled || webtoon || game || imgsrc, [disabled, webtoon, game, imgsrc]);
  const isImgsrcDisabled = useMemo(() => disabled || webtoon || game || lecture, [disabled, webtoon, game, lecture]);

  const handleClickToon = (e) => {
    setWebtoon(e.target.checked);
    setLecture(false);
    setImgsrc(false);
  };
  const handleClickGame = (e) => {
    setGame(e.target.checked);
    setLecture(false);
    setImgsrc(false);
  };
  const handleClickLecture = (e) => {
    setLecture(e.target.checked);
    setWebtoon(false);
    setGame(false);
    setIsAdultOnly(false);
    setImgsrc(false);
  };
  const handleClickImgsrc = (e) => {
    setImgsrc(e.target.checked);
    setWebtoon(false);
    setGame(false);
    setLecture(false);
  };

  return (
    <Field className="hubWrite_field_document_type" mt={3}>
      <Title checkboxVisible={false} marginRight="auto">
        {t('document.productType')}
      </Title>
      <Box display="flex" alignItems="center" color="#000000DE" letterSpacing="0.15px">
        <FormGroup sx={{ ml: -1, '& label': { margin: '0 !important' } }}>
          <FormControlLabel
            control={<Checkbox checked={webtoon} disabled={isWebtoonDisabled} onChange={handleClickToon} />}
            label={t('document.toon')}
            sx={{
              '& .MuiFormControlLabel-label': {
                fontSize: '16px',
                lineHeight: '24px',
              },
            }}
          />
        </FormGroup>
        <FormGroup sx={{ ml: 1, '& label': { margin: '0 !important' } }}>
          <FormControlLabel
            control={<Checkbox checked={game} disabled={isGameDisabled} onChange={handleClickGame} />}
            label={t('document.game')}
            sx={{
              '& .MuiFormControlLabel-label': {
                fontSize: '16px',
                lineHeight: '24px',
              },
            }}
          />
        </FormGroup>
        <FormGroup sx={{ ml: 1, '& label': { margin: '0 !important' } }}>
          <FormControlLabel
            control={<Checkbox checked={lecture} disabled={isLectureDisabled} onChange={handleClickLecture} />}
            label={t('document.lecture')}
            sx={{
              '& .MuiFormControlLabel-label': {
                fontSize: '16px',
                lineHeight: '24px',
              },
            }}
          />
        </FormGroup>
        <FormGroup sx={{ ml: 1, '& label': { margin: '0 !important' } }}>
          <FormControlLabel
            control={<Checkbox checked={imgsrc} disabled={isImgsrcDisabled} onChange={handleClickImgsrc} />}
            label={t('document.imgsrc')}
            sx={{
              '& .MuiFormControlLabel-label': {
                fontSize: '16px',
                lineHeight: '24px',
              },
            }}
          />
        </FormGroup>
      </Box>
    </Field>
  );
}
