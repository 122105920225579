import React, { useEffect, useMemo, useState } from 'react';
import { TwinkleSaleWriteContainer, TwinkleSaleWriteFooter } from './index.styles';
import { Box, Button, Stack, Typography } from '@mui/material';
import palette from 'theme/palette';
import Calendar from 'acon-mui/components/Calendar/Calendar';
import TwinkleDiscountList from '../components/TwinkleDiscountList/TwinkleDiscountList';
import { Whisper } from 'rsuite';
import { ListTooltip } from '../components/TwinkleDiscountList/TwinkleDiscountList.styles';
import TwinkleProductModal from '../components/TwinkleProductModal/TwinkleProductModal';
import { useTranslation } from 'react-i18next';
import TwinkleModal from '../components/TwinkleModal/TwinkleModal';
import { MODAL_REFUSE_STATUS } from '../components/TwinkleModal/TwinkleModal.types';
import _ from 'lodash';
import { useTwinkleSaleStore } from 'stores/promotions/twinkle-sale/useTwinkleSaleStore';
import { useHistory } from 'react-router-dom';
import { EXCHANGE_RATE, getValidationSchema } from '../components/TwinkleDiscountList/TwinkleDiscountList.constants';
import { EPromotionDiscountType, ESearchPromotionKey, LanguageCodeEnum, useFetchPromotionsQuery, useRegisterBrandPromotionMutation } from 'generated/graphql';
import { IProduct } from '../components/TwinkleDiscountList/TwinkleDiscountList.types';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';
import dayjs from 'dayjs';
import { Nullable } from 'types/common';

const getTwinkleSaleState = (state) => ({ id: state.id, since: state.since, until: state.until, availability: state.availability });
export const getTwinkleSaleSetState = (state) => ({
  setId: state.setId,
  setStatus: state.setStatus,
  setSince: state.setSince,
  setUntil: state.setUntil,
  setAvailability: state.setAvailability,
  setToastStatus: state.setToastStatus,
});

export default function (props) {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const { id, since, until, availability } = useTwinkleSaleStore(getTwinkleSaleState);
  const { setSince, setUntil, setAvailability, setToastStatus } = useTwinkleSaleStore(getTwinkleSaleSetState);

  const language = useMemo<LanguageCodeEnum>(() => (i18n.language === LanguageCodeEnum.Ko ? LanguageCodeEnum.Ko : LanguageCodeEnum.En), [i18n.language]);

  const [statusTwinkleModal, setStatusTwinkleModal] = useState<MODAL_REFUSE_STATUS | null>(null);
  const [isShowProductListModal, setIsShowProductListModal] = useState(false);
  const [isShowTwinkleModal, setIsShowTwinkleModal] = useState(false);
  const [isShowChangeQuantityTooltip, setIsShowChangeQuantityTooltip] = useState(false);
  const [isShowApplicationTimeTooltip, setIsShowApplicationTimeTooltip] = useState(false);
  const [discountAssetsList, setDiscountAssetsList] = useState([]);

  const validationSchema = useMemo(() => getValidationSchema(t, language), [t, language]);
  const { refetch: fetchPromotionsRefetch } = useFetchPromotionsQuery({
    variables: {
      search: {
        conceptId: 2,
        since: null,
        until: null,
        key: ESearchPromotionKey.PromotionTitle,
        status: null,
      },
      page: 1,
      limit: 100,
    },
    fetchPolicy: 'no-cache',
  });
  const [registerBrandPromotion] = useRegisterBrandPromotionMutation({
    onCompleted: (data) => {
      if (data?.registerBrandPromotion?.createdAt) setToastStatus('success');
      history.push('/manager/promotion/twinkle-sale');
    },
    onError: async (error) => {
      const {
        data: {
          fetchPromotions: { data: newFetchPromotionsData },
        },
      } = await fetchPromotionsRefetch();

      const newFetchPromotion =
        filter(sortBy(newFetchPromotionsData, ['since']), (promotion) => {
          return promotion.status === 'DRAFT' && dayjs().isBefore(promotion.since);
        })[0] || null;

      const prevFetchPromotion = newFetchPromotionsData.find((promotion) => promotion.id === id);

      if (newFetchPromotion.since !== since || newFetchPromotion.until !== until) {
        setStatusTwinkleModal(MODAL_REFUSE_STATUS.APPLICATION_TIME);
        setIsShowTwinkleModal(true);
        setSince(newFetchPromotion.since);
        setUntil(newFetchPromotion.until);
        return;
      }

      if (prevFetchPromotion.availability.available !== availability.available) {
        if (prevFetchPromotion.availability.available === 0) {
          setStatusTwinkleModal(MODAL_REFUSE_STATUS.RUNNING_OUT_QUANTITY);
          setIsShowTwinkleModal(true);
        } else {
          setStatusTwinkleModal(MODAL_REFUSE_STATUS.CHANGE_QUANTITY);
          setIsShowTwinkleModal(true);
          setAvailability({ ...availability, available: prevFetchPromotion.availability.available });
          setValue('quantity', prevFetchPromotion.availability.available);
          trigger();
        }
        return;
      }

      alert(error);
    },
  });

  const methods = useForm<{ products: IProduct[]; quantity: Nullable<number> }>({
    defaultValues: { products: [], quantity: null },
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const {
    watch,
    reset,
    setValue,
    handleSubmit,
    trigger,
    formState: { isValid },
  } = methods;

  const products = watch('products');

  const handleOnClickSuccessModal = () => {
    if (statusTwinkleModal === MODAL_REFUSE_STATUS.APPLICATION_TIME) setIsShowApplicationTimeTooltip(true);
    else if (statusTwinkleModal === MODAL_REFUSE_STATUS.CHANGE_QUANTITY) setIsShowChangeQuantityTooltip(true);
    else if (statusTwinkleModal === MODAL_REFUSE_STATUS.RUNNING_OUT_QUANTITY) history.push('/manager/promotion/twinkle-sale');
  };

  const handleOnSuccess = async (data: any) => {
    setDiscountAssetsList(data.products);
    await registerBrandPromotion({
      variables: {
        input: {
          promotionId: id,
          items: data.products.map((product) => ({
            assetId: product.id,
            discountType: product.discountType,
            discountValue:
              language === LanguageCodeEnum.Ko
                ? product.discountValue
                : product.discountType === EPromotionDiscountType.Fixed
                ? product.discountValue * EXCHANGE_RATE
                : product.discountValue,
          })),
        },
      },
    });
  };

  useEffect(() => {
    if (!id) {
      alert(t('twinkleSalePage.unusualApproach'));
      history.push('/manager/promotion/twinkle-sale');
    }
  }, [history, id, t]);

  useEffect(() => {
    if (discountAssetsList && availability?.available) {
      const result = discountAssetsList.map((product) => ({
        ...product,
        discountValue: product.discountValue ? product.discountValue : 0,
        discountType: product.discountType ? product.discountType : EPromotionDiscountType.Percent,
      }));
      reset({ products: result, quantity: availability.available });
    }
  }, [availability, discountAssetsList, reset]);

  useEffect(() => {
    const TOOLTIP_TIME_OUT = 5000;
    if (isShowChangeQuantityTooltip) setTimeout(() => setIsShowChangeQuantityTooltip(false), TOOLTIP_TIME_OUT);
    if (isShowApplicationTimeTooltip) setTimeout(() => setIsShowApplicationTimeTooltip(false), TOOLTIP_TIME_OUT);
  }, [isShowChangeQuantityTooltip, isShowApplicationTimeTooltip]);

  if (!id) return <></>;

  return (
    <FormProvider {...methods}>
      <TwinkleModal status={statusTwinkleModal} isShow={isShowTwinkleModal} onClose={() => setIsShowTwinkleModal(false)} onSuccess={handleOnClickSuccessModal} />
      <TwinkleProductModal
        isShow={isShowProductListModal}
        discountAssetsList={products}
        onClose={() => setIsShowProductListModal(false)}
        setDiscountAssetsList={setDiscountAssetsList}
        remainingQuantity={availability.available}
      />
      <TwinkleSaleWriteContainer fullWidth>
        <Typography variant="h4" color={palette.light.text.primary} pt={1} pb={2}>
          {t('twinkleSalePage.titleApply')}
        </Typography>
        <Calendar since={since} until={until} status="ready" isShowTooltip={isShowApplicationTimeTooltip} />
        <Stack direction="row" justifyContent="space-between" alignItems="center" pt={8} pb={2} gap={2}>
          <Typography variant="h5" color={palette.light.text.primary}>
            {t('twinkleSalePage.discountedGoods')}
          </Typography>
          <Stack direction="row" justifyContent="flex-end" alignItems="center">
            <Typography variant="subtitle2" color={palette.light.text.primary} fontWeight="400" mr={1}>
              <b>
                {t('twinkleSalePage.selectedQuantity')} {products.length} {t('twinkleSalePage.quantity')}
              </b>
            </Typography>
            <Whisper placement="bottom" open={isShowChangeQuantityTooltip} speaker={<ListTooltip>{t('twinkleSalePage.remainingQuantityDescription')}</ListTooltip>}>
              <Typography variant="subtitle2" color={palette.light.text.primary} fontWeight="400">
                ({t('twinkleSalePage.remainingQuantity')} : {availability?.available})
              </Typography>
            </Whisper>
            <Button
              color="primary"
              size="medium"
              variant="contained"
              sx={{ marginLeft: 2, padding: '6px 16px' }}
              onClick={() => setIsShowProductListModal(!isShowProductListModal)}
            >
              {t('twinkleSalePage.productSelection')}
            </Button>
          </Stack>
        </Stack>
        <TwinkleDiscountList type="edit" />
        <TwinkleSaleWriteFooter>
          <Box display="flex" justifyContent="flex-end" alignItems="center" p="12px" maxWidth="1060px" m="0 auto" pr="0">
            <Button
              color="primary"
              disabled={!isValid || !products.length}
              size="medium"
              variant="contained"
              sx={{ minWidth: '129px', whiteSpace: 'pre' }}
              onClick={handleSubmit(handleOnSuccess)}
            >
              {t('twinkleSalePage.applyFlashSale')}
            </Button>
          </Box>
        </TwinkleSaleWriteFooter>
      </TwinkleSaleWriteContainer>
    </FormProvider>
  );
}
