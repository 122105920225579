import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Editor from 'common/editor';
import parse from 'html-react-parser';
import { AppContext } from 'app';
import { HtmlView } from './style';
import { Box, Typography } from '@mui/material';
import { useDocumentStore } from 'boards/DetailBoardWrite/stores';

export default ({ isChecked }) => {
  // 번역도구
  const { t, ready } = useTranslation();
  const { userInfo } = useContext(AppContext);
  const { isDisp, language, contentHead, contentBody, setContentHead, setContentBody } = useDocumentStore((state) => ({
    isDisp: state.isDisp,
    language: state.language,
    contentHead: state.contentHead,
    contentBody: state.contentBody,
    setContentHead: state.setContentHead,
    setContentBody: state.setContentBody,
  }));

  if (!ready) return <div></div>;

  return (
    <>
      <Box fontSize="12px" fontWeight="400" position="relative">
        <Typography mb="12px" color="#000000DE" fontWeight="500" fontSize="14px" lineHeight="22px" letterSpacing="0.1px">
          {t('basicDetail.introduction')}
        </Typography>
        {!isDisp && isChecked && (
          <Editor
            key={language}
            isAdmin={userInfo.isAdmin}
            isDisp={isDisp}
            docId="introduction"
            contents={contentHead}
            placeholder={t('basicDetail.introductionBody')}
            onChange={(value) => {
              setContentHead(value);
            }}
            defaultHeight={250}
            showAlertMessage={() => {}}
            isWarningVisible={false}
          />
        )}
        {(isDisp || !isChecked) && <HtmlView height="250px">{parse(contentHead || '')}</HtmlView>}
      </Box>
      <Box mt="24px" fontSize="12px" fontWeight="400" position="relative">
        <Typography mb="12px" color="#000000DE" fontWeight="500" fontSize="14px" lineHeight="22px" letterSpacing="0.1px">
          {t('basicDetail.content')}
        </Typography>
        {!isDisp && isChecked && (
          <Editor
            key={language}
            isAdmin={userInfo.isAdmin}
            isDisp={isDisp}
            docId="content"
            contents={contentBody}
            placeholder={t('basicDetail.contentBody')}
            onChange={(value) => {
              setContentBody(value);
            }}
            showAlertMessage={() => {}}
          />
        )}
        {(isDisp || !isChecked) && <HtmlView>{parse(contentBody || '')}</HtmlView>}
      </Box>
    </>
  );
};
